import React, { Component } from "react";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import { Modal, Button } from "antd";
import TransactionActions from "../../../../redux/actions/transactionActions";
import {
  customStyles,
  renderEntity,
  renderEntityName,
} from "../../../common/CommonMethods";
import TransactionForm from "../../../forms/Transactions/form";
import EntityActions from "../../../../redux/actions/entityActions";
import Confirm from "../../../common/Confirm";
import moment from "moment";
import { toast } from "../../../../components/common/Toast";
import auth from "../../../../services/authService";
class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectEntityOptions: [],
      selectedTransaction: null,
      open: false,
      transactionId: null,
    };
  }

  componentDidMount() {
    let { fetchTransactions, fetchAllEntities } = this.props;
    fetchTransactions();
    fetchAllEntities();
  }

  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.existingEntity !== nextProps.existingEntity &&
      nextProps.currentUser.id
    ) {
      this.setState({
        selectEntityOptions:
          nextProps.existingEntity &&
          nextProps.existingEntity.map((entity) => {
            if (entity.entity_company !== null)
              return {
                value: entity.id,
                text: `${entity.entity_company.name} ${
                  entity.entity_company.nickname
                    ? `(${entity.entity_company.nickname})`
                    : ""
                }`,
                value: entity.id,
              };
            else if (entity.entity_trust !== null)
              return {
                value: entity.id,
                text: `${entity.entity_trust.name} ${
                  entity.entity_trust.nickname
                    ? `(${entity.entity_trust.nickname})`
                    : ""
                }`,
                value: entity.id,
              };
            else if (entity.entity_individual !== null)
              return {
                value: entity.id,
                text: `${this.renderIndividualName(entity)}`,
                value: entity.id,
              };
          }),
      });
    }
  };

  renderIndividualName = (entity) => {
    let name = "";
    for (let key in entity.entity_individuals) {
      if (name.length > 0) {
        return (name +=
          " " +
          "&" +
          " " +
          entity.entity_individuals[key].given_name +
          " " +
          entity.entity_individuals[key].sur_name +
          " " +
          `${
            entity.entity_individuals[key].nickname
              ? `(${entity.entity_individuals[key].nickname})`
              : ""
          }`);
      }

      name +=
        entity.entity_individuals[key].given_name +
        " " +
        entity.entity_individuals[key].sur_name +
        " " +
        `${
          entity.entity_individuals[key].nickname
            ? `(${entity.entity_individuals[key].nickname})`
            : ""
        }`;
    }

    return name;
  };

  addNew = () => {
    this.setState({
      modal: true,
      selectedTransaction: null,
    });
  };

  handleSubmit = () => {};
  handleCancel = () => {
    this.setState({
      modal: false,
    });
  };

  onSubmit = async (values) => {
    let { history, createTransaction, updateTransaction } = this.props;
    if (values.id) {
      updateTransaction({ ...values }, history);
    } else {
      createTransaction({ ...values }, history);
    }
    this.handleCancel();
  };

  onDeleteTransaction = (id) => {
    let { history, deleteTransaction } = this.props;
    deleteTransaction(this.state.transactionId, history);
    this.close();
  };

  editTransaction = (row) => {
    this.setState({
      modal: true,
      selectedTransaction: row,
    });
  };

  open = (id) => {
    this.setState({ open: true, transactionId: id });
  };

  close = () => this.setState({ open: false, transactionId: null });

  exportAllocations = async () => {
    let res = await auth.exportInvestorAllocations();
    if (res.data && res.data.data) {
      window.open(res.data.data.url, "_blank").focus();
    } else {
      toast.error("Something went wrong.");
    }
  };

  render() {
    const columns = [
      {
        name: "Type",
        selector: "type",
        sortable: false,
        className: "type no-wrap",
      },
      {
        name: "Date",
        selector: "date",
        sortable: false,
        className: "date no-wrap",
      },
      {
        name: "Entity",
        selector: "date",
        sortable: false,
        className: "date no-wrap",
        cell: (row) => (
          <div onClick={() => this.handleChange(row)}>
            {row && row.entity && renderEntityName(row.entity)}
          </div>
        ),
      },
      {
        name: "Company",
        selector: "company_name",
        sortable: false,
        className: "company no-wrap",
        cell: (row) => (
          <>
            <div className="wrap">
              <span>{row.company_name}</span>
            </div>
          </>
        ),
      },
      {
        name: "ASX Code",
        selector: "asx_code",
        sortable: false,
        className: "asx_code no-wrap",
        center: true,
      },
      {
        name: "No. Of Shares",
        selector: "no_of_shares",
        sortable: false,
        className: "asx_code no-wrap",
      },
      {
        name: "Price",
        selector: "price",
        sortable: false,
        className: "price no-wrap",
        cell: (row) => (
          <>
            <div className="wrap">
              <span>{row.price ? `$${row.price}` : ""}</span>
            </div>
          </>
        ),
      },
      {
        name: "Brokerage Fee",
        selector: "brokerage",
        sortable: false,
        className: "total_amount no-wrap",
        cell: (row) => (
          <>
            <div className="wrap">
              <span>{row.brokerage ? `$${row.brokerage}` : ""}</span>
            </div>
          </>
        ),
      },
      {
        name: "Total Amount",
        selector: "total_amount",
        sortable: false,
        className: "total_amount no-wrap",
        cell: (row) => (
          <>
            <div className="wrap">
              <span>{row.total_amount ? `$${row.total_amount}` : ""}</span>
            </div>
          </>
        ),
      },
      {
        name: "",
        selector: "actions",
        sortable: false,
        className: "actions no-wrap",
        minWidth: "200px",
        cell: (row) => (
          <>
            <div className="wrap">
              <div className="btn-group">
                <button
                  className="btn entity__buttonEdit"
                  onClick={() => {
                    this.editTransaction(row);
                  }}
                >
                  Edit
                </button>
                <button
                  type="button"
                  className="btn-outline-default btn__transactionDelete"
                  onClick={() => this.open(row.id)}
                >
                  Delete
                </button>
                <Confirm
                  message="Are you sure you want to delete this transaction?"
                  show={this.state.open}
                  handleDelete={() => this.onDeleteTransaction(row.id)}
                  handleClose={this.close}
                />
              </div>
            </div>
          </>
        ),
      },
    ];

    return (
      <div className="page-deals page-all-deals">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <h1>Transactions</h1>
            </div>
            <div className="col-sm-6">
              <button
                className="btn-outline-default btn__transactionDelete"
                onClick={this.exportAllocations}
                style={{ float: "right" }}
              >
                <i class="fa fa-download"></i>Export
              </button>
              <button
                className="btn btn-outline-primary entity__buttonEdit"
                onClick={this.addNew}
                style={{ float: "right" }}
              >
                <i class="fa fa-plus"></i>Add New
              </button>
            </div>
          </div>
          <div className="md-table-normal table-responsive">
            {/* <Button className="float-right" type="primary" size="small" onClick={this.exportAllocations}>Export</Button> */}
            <DataTable
              noHeader
              highlightOnHover
              columns={columns}
              data={this.props.transactions}
              onRowClicked={this.handleChange}
              pointerOnHover={true}
              sortServer={true}
              customStyles={customStyles}
            />
          </div>
          <Modal
            visible={this.state.modal}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={null}
          >
            <h3>
              {this.state.selectedTransaction ? "Edit" : "Create New"}{" "}
              Transaction
            </h3>

            <div className="deal-form-content">
              <TransactionForm
                entities={this.state.selectEntityOptions}
                onSubmit={this.onSubmit}
                onCancel={this.handleCancel}
                initialValues={
                  this.state.selectedTransaction
                    ? {
                        ...this.state.selectedTransaction,
                        date: this.state.selectedTransaction
                          ? new Date(
                              this.state.selectedTransaction?.formatted_date
                            )
                          : null,
                      }
                    : null
                }
              />
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

const {
  fetchTransactions,
  createTransaction,
  updateTransaction,
  deleteTransaction,
} = TransactionActions;
const { fetchEntities } = EntityActions;

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
  transactions: state.transactions.transactions,
  existingEntity: state.entities.entities,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTransactions: (payload) => fetchTransactions(dispatch, payload),
  fetchAllEntities: (payload) => fetchEntities({ dispatch, payload }),
  createTransaction: (payload, history) =>
    createTransaction({ dispatch, payload, history }),
  updateTransaction: (payload, history) =>
    updateTransaction({ dispatch, payload, history }),
  deleteTransaction: (id, history) =>
    deleteTransaction({ dispatch, id, history }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
