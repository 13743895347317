import React, { Component } from "react";
import { Form, reduxForm, Field, change } from "redux-form";
import { FormField, MaskedPriceFormat } from "../../../layout/FormField";
import { required, numericality } from "redux-form-validators";
import { connect } from "react-redux";
import EntityActions from "../../../../redux/actions/entityActions";
import ButtonLoader from "../../../common/ButtonLoader";
import AlertComponent from "../../../common/Alert/Alert";
import { shares } from "../../../../utils/customValidators";
import { scrollToFirstError } from "../../../../utils/scroller";
import { MIN_BID_AMOUNT } from "../../../../utils/constants";

class IpoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_expired: false,
    };
  }

  componentDidMount = () => {
    let { fetchEntitiesData, currentUser } = this.props;

    if (currentUser) {
      fetchEntitiesData();
    }
  };

  onShareCountChangeHander = (e) => {
    this.props.change(
      "investment_amount",
      parseFloat(e.target.value * this.props.currentDeal.ipo_price).toFixed(2)
    );
  };

  onInvestmentChangeHandler = (e) => {
    if (typeof e !== "object") {
      return this.props.currentDeal.type !== "market"
        ? this.props.change(
            "share_count",
            (
              parseFloat(e) / parseFloat(this.props.currentDeal.ipo_price)
            ).toFixed(0)
          )
        : null;
    }
  };

  onBlur = (e) => {
    const { form } = this.props;
    if (
      form &&
      form.IpoForm &&
      form.IpoForm.values &&
      form.IpoForm.values.share_count
    ) {
      this.props.change(
        "investment_amount",
        parseFloat(
          form.IpoForm.values.share_count * this.props.currentDeal.ipo_price
        ).toFixed(2)
      );
    }
  };

  onEntityChange = (e) => {
    let selectedEntity = e.target.value;
    let entity = this.props.selectEntityOptions.find(
      (entity) => parseInt(entity.value) === parseInt(selectedEntity)
    );

    if (entity) {
      this.setState({
        is_expired: entity.is_si_form_expired,
      });
    } else {
      this.setState({
        is_expired: false,
      });
    }
  };

  render() {
    let { handleSubmit, buttonTitle, form, ipo } = this.props;
    let bid_min_amount = ipo && ipo.min_bid ? ipo.min_bid : MIN_BID_AMOUNT;

    return (
      <div>
        {/* {form.IpoForm &&
          form.IpoForm.values &&
          parseFloat(form.IpoForm.values.share_count) === 0 && (
            <AlertComponent
              message="Information"
              type="info"
              description="Please contact us on 1300 373 522 to change your deal."
            />
          )} */}

        {ipo && ipo.status && ipo.status === "Suspended" && (
          <AlertComponent
            message="Deal Suspended."
            type="error"
            description="Please contact us on 1300 373 522 or email us at info@180markets.com.au to change your deal."
          />
        )}
        <h3 style={{ paddingLeft: 20, paddingTop: 20 }}>
          {this.props.currentDeal && this.props.currentDeal.type === "market"
            ? "Indication of interest1"
            : `Submit Bid For ${this.props.currentDeal.company_name}`}
        </h3>
        <p>
          {this.props.currentDeal && this.props.currentDeal.type === "market"
            ? "This is not a 180 deal, if you have interest we will contact the lead broker and attempt to get you an allocation"
            : ""}
        </p>
        <div className="deal-form-content">
          {this.props.currentDeal &&
          this.props.currentDeal.type === "market" ? (
            <Form onSubmit={handleSubmit}>
              <div className="all-fields">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="field">
                      <div className="field-input">
                        <label className="title">Investment Amount</label>
                        <div className="field-input">
                          <Field
                            className="form-control"
                            name="investment_amount"
                            component={MaskedPriceFormat}
                            onChange={this.onInvestmentChangeHandler}
                            type="number"
                            validate={[
                              required(),
                              numericality({
                                ">": 0,
                              }),
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="button-group">
                  <div className="col-sm-12">
                    <ButtonLoader
                      type="submit"
                      style={{ marginTop: "10px" }}
                      className="btn btn-primary btn-medium"
                    >
                      Submit
                    </ButtonLoader>
                  </div>
                </div>
              </div>
            </Form>
          ) : (
            <Form onSubmit={handleSubmit}>
              <div className="all-fields">
                <div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="field">
                        <div className="field-input">
                          <label className="title">Select Entity </label>
                          <div className="field-input">
                            <Field
                              type="hidden"
                              name="bid_id"
                              component={FormField}
                              defaultValue={this.props.initialValues?.bid_id}
                            />
                            <Field
                              className="form-control"
                              name="entity_id"
                              component={FormField}
                              type="select"
                              validate={[required()]}
                              // onChange={this.onEntityChange}
                              options={
                                this.props.selectEntityOptions &&
                                this.props.selectEntityOptions
                              }
                            />
                            {this.state.is_expired && (
                              <span class="form__field-error">
                                Sophisticated Investor Form is expired. Please
                                upload new Sophisticated Investor Form to bid
                                with this entity.
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="field">
                        <div className="field-input">
                          <label className="title">Share Count </label>
                          <div className="field-input">
                            <Field
                              className="form-control"
                              name="share_count"
                              component={FormField}
                              type="number"
                              onBlurHandler={this.onShareCountChangeHander}
                              validate={[
                                required(),
                                numericality({
                                  ">": 0,
                                }),
                              ]}
                              normalize={shares}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="field">
                      <div className="field-input">
                        <label className="title">Investment Amount</label>
                        <div className="field-input">
                          <Field
                            className="form-control"
                            name="investment_amount"
                            component={MaskedPriceFormat}
                            onChange={this.onInvestmentChangeHandler}
                            type="number"
                            onBlurHandler={this.onBlur}
                            validate={
                              this.props.initialValues &&
                              this.props.initialValues.investment_amount
                                ? [
                                    required(),
                                    numericality({
                                      ">=": parseFloat(
                                        this.props.initialValues
                                          .investment_amount
                                      ),
                                      msg: {
                                        ">=": `Min bid amount must be greater than original bid amount $${this.props.initialValues.investment_amount}.`,
                                      },
                                    }),
                                  ]
                                : [
                                    required(),
                                    numericality({
                                      ">=": bid_min_amount,
                                      msg: {
                                        ">=": `Min bid amount is $${bid_min_amount}.`,
                                      },
                                    }),
                                  ]
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {form.IpoForm &&
                  form.IpoForm.values &&
                  form.IpoForm.values.investment_amount &&
                  this.props.initialValues &&
                  this.props.initialValues.investment_amount &&
                  parseFloat(form.IpoForm.values.investment_amount) <
                    parseFloat(this.props.initialValues.investment_amount) && (
                    <AlertComponent
                      message="Information"
                      type="error"
                      description="Contact us at info@180markets.com.au if you would like to reduce your bid. When possible, we will attempt to reduce your bid, but cannot guarantee it."
                    />
                  )}
              </div>
              <div className="row">
                <div className="button-group">
                  <div className="col-sm-12">
                    <ButtonLoader
                      disabled={
                        this.state.is_expired ||
                        (ipo && ipo.status && ipo.status === "Suspended"
                          ? true
                          : false)
                      }
                      style={{ marginTop: "10px" }}
                      type="submit"
                      className="btn btn-primary btn-medium"
                    >
                      {buttonTitle ? buttonTitle : "Submit"}
                    </ButtonLoader>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    form: state.form,
  };
};

const { fetchEntities } = EntityActions;

const mapDispatchToProps = (dispatch) => ({
  fetchEntitiesData: () => fetchEntities(dispatch),
});

export default reduxForm({
  form: "IpoForm",
  enableReinitialize: true,
})(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    IpoForm
  )
);
