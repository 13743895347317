import React, { Component } from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import moment from "moment";
import NumberFormat from "react-number-format";
import { FileUpload } from "../../common/Graphics";
import IpoForm from "./IpoForm/IpoForm";
import IpoActions from "../../../redux/actions/ipoActions";
import EntityActions from "../../../redux/actions/entityActions";
import { withRouter } from "react-router-dom";
import { BaseUrl } from "../../../services/settings";
import ModalComponent from "../../common/Modal";
import { renderData } from "../../common/CommonMethods";

class IpoView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectEntityOptions: [],
      open: false,
      openLightBox: false,
      modalOpen: false,
      file: "",
      anotherBid: false,
      selectedBid: null,
    };
  }

  openLightBox = (file) => {
    this.setState({ openLightBox: true, file });
  };

  closeLightBox = () => {
    this.setState({ openLightBox: false });
  };

  modalOpen = () => {
    this.setState({ modalOpen: true });
  };

  modalClose = () => {
    this.setState({ modalOpen: false });
  };

  componentDidMount = () => {
    let {
      match,
      fetchSelectedIpoData,
      fetchAllEntities,
      currentUser,
      history,
    } = this.props;
    fetchAllEntities();
    fetchSelectedIpoData(match.params.uuid);

    const isApproved = currentUser.is_approved === "Approved" ? true : false;
    if (!isApproved) {
      history.push("/user/deals");
    }
  };

  cancelBidHanler = (id) => {
    this.setState({ open: false });
    let { cancelBid, history } = this.props;
    cancelBid({ bidId: id }, history);
  };

  editIndicationHandler = (id, bidid) => {
    this.props.history.push(`/user/edit-ipo/${id}/${bidid}`);
  };

  editBidHandler = (id, bidid) => {
    this.props.history.push(`/user/edit-ipo/${id}/${bidid}`);
  };

  onSubmitBid = async (values) => {
    let { submitBid, ipo, submitIpoIndication, history } = this.props;
    if (!ipo.myBid) {
      if (ipo.type === "market") {
        submitIpoIndication(
          {
            ...values,
            id: ipo.id,
          },
          history
        );
      } else if (ipo.type === "180") {
        Modal.confirm({
          title: "Confirm",
          width: 700,
          okButtonProps: {
            type: "primary",
            size: "large",
          },
          cancelButtonProps: {
            size: "large",
          },
          icon: <ExclamationCircleOutlined />,
          content: (
            <p className="pconfirm">
              {" "}
              By submitting a bid, you are making a binding and irrevocable
              offer to acquire the securities, on the terms set out in the
              information available here, in the{" "}
              {ipo && ipo.term_sheet ? (
                <a
                  target="_blank"
                  href={`${BaseUrl}storage/${ipo.term_sheet.id}/${ipo.term_sheet.file_name}`}
                >
                  Term sheet
                </a>
              ) : (
                "Term sheet"
              )}{" "}
              and in the{" "}
              {
                <a
                  target="_blank"
                  href="https://180markets.com.au/investor/terms&conditions"
                >
                  Investor Terms
                </a>
              }
              . You confirm you make each of the representations and warranties
              specified in the Investor Terms. Your bid does not guarantee that
              you will receive an allocation. If your bid is successful, you
              will be required to pay to acquire the securities in accordance
              with your bid and the Investor Terms, subject to any scale back.
            </p>
          ),
          okText: "Confirm",
          cancelText: "Cancel",
          async onOk() {
            await submitBid({ ...values, id: ipo.id }, history);
          },
          onCancel() {},
        });
      }
    }
  };

  renderIndividualName = (entity) => {
    let name = "";
    for (let key in entity.entity_individuals) {
      if (name.length > 0) {
        return (name +=
          " " +
          "&" +
          " " +
          entity.entity_individuals[key].given_name +
          " " +
          entity.entity_individuals[key].sur_name +
          " " +
          `${
            entity.entity_individuals[key].nickname
              ? `(${entity.entity_individuals[key].nickname})`
              : ""
          }`);
      }

      name +=
        entity.entity_individuals[key].given_name +
        " " +
        entity.entity_individuals[key].sur_name +
        " " +
        `${
          entity.entity_individuals[key].nickname
            ? `(${entity.entity_individuals[key].nickname})`
            : ""
        }`;
    }

    return name;
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.existingEntity !== nextProps.existingEntity &&
      nextProps.currentUser.id
    ) {
      this.setState({
        selectEntityOptions:
          nextProps.existingEntity &&
          nextProps.existingEntity.map((entity) => {
            if (entity.entity_company !== null)
              return {
                value: entity.id,
                text: `${entity.broker_name} - ${entity.entity_company.name} ${
                  entity.entity_company.nickname
                    ? `(${entity.entity_company.nickname})`
                    : ""
                }`,
                is_si_form_expired: entity.is_si_form_expired,
              };
            else if (entity.entity_trust !== null)
              return {
                value: entity.id,
                text: `${entity.broker_name} - ${entity.entity_trust.name} ${
                  entity.entity_trust.nickname
                    ? `(${entity.entity_trust.nickname})`
                    : ""
                }`,
                is_si_form_expired: entity.is_si_form_expired,
              };
            else if (entity.entity_individual !== null)
              return {
                value: entity.id,
                text: `${entity.broker_name} - ${this.renderIndividualName(
                  entity
                )}`,
                is_si_form_expired: entity.is_si_form_expired,
              };
          }),
      });
    }
  };

  onBidNow = (id) => {
    this.setState({
      anotherBid: true,
      selectedBid: null,
    });
    // this.props.onBidNow(id, true);
  };

  render() {
    let { ipo, currentUser, bidStatus } = this.props;
    let { anotherBid } = this.state;
    console.log("anotherBid", anotherBid);
    let entitiesNotUsed = [...this.state.selectEntityOptions].filter(
      (entity) => {
        return !(
          ipo &&
          ipo.myBids &&
          ipo.myBids.some((bid) => bid.entity_id === entity.value)
        );
      }
    );
    return (
      <main className="page-deal-add-doc">
        <div className="md-top-deal">
          <div className="container">
            <div className="deal-inner">
              <div className="row">
                <div className="col-md-2">
                  <div className="logo">
                    <div className="inner">
                      <img
                        src={
                          ipo &&
                          ipo.logo &&
                          `${BaseUrl}/storage/${ipo.logo.id}/${ipo.logo.file_name}`
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="information">
                    <h1>{ipo && ipo.company_name}</h1>
                    {ipo && ipo.category == 180 && ipo.asx_code && (
                      <p className="sub-title">ASX: {ipo.asx_code}</p>
                    )}
                    <div className="detail">
                      <div className="row">
                        {ipo && ipo.ipo_price && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Price</p>
                              <p className="number">{`$${ipo.ipo_price}`}</p>
                            </div>
                          </div>
                        )}

                        {ipo && ipo.raise_amount && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Raise Amount</p>
                              <p className="number">{`${renderData(
                                ipo.raise_amount
                              )}`}</p>
                            </div>
                          </div>
                        )}

                        {ipo && ipo.market_cap && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">
                                {ipo.category === "180"
                                  ? "Market Capitalisation"
                                  : "Valuation"}
                              </p>
                              <p className="price">{`${renderData(
                                ipo.market_cap
                              )}`}</p>
                            </div>
                          </div>
                        )}
                        {ipo && ipo.free_options && ipo.dividend_option && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Free Options</p>
                              <p className="price">
                                {`${ipo.dividend_option}:${ipo.free_options} ${ipo?.option_details ? ipo.option_details : ''}`}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md-tabs tab-deal-add-doc">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                {ipo.listing_date && (
                  <p>
                    <b>Listing Date: </b>{" "}
                    {moment(ipo.listing_date, "DD/MM/YYYY").format(
                      "MMM. DD, YYYY"
                    )}
                  </p>
                )}
                {ipo.bids_due && (
                  <p>
                    <b>Bids Due: </b>{" "}
                    {moment(ipo.bids_due, "DD/MM/YYYY HH:mm").format(
                      "MMM. DD, YYYY hh:mm A"
                    )}
                  </p>
                )}

                {ipo && ipo.industry && (
                  <p>
                    <b>Industry: </b> {`${ipo.industry}`}
                  </p>
                )}

                <div className="row mt-5">
                  {this.props.ipo && (
                    <div className="col-md-12 mb-4">
                      {ipo && ipo.term_sheet && (
                        <div className="smd-file-detail clearfix">
                          <div className="file-image">
                            <img src={FileUpload} alt="" width={42} />
                          </div>
                          <div className="file-meta">
                            <span className="name">Term Sheet</span>
                            <div className="view clearfix">
                              <a
                                target="_blank"
                                href={`${BaseUrl}storage/${ipo.term_sheet.id}/${ipo.term_sheet.file_name}`}
                              >
                                View document
                              </a>
                            </div>
                            <ModalComponent
                              fileName={ipo.term_sheet.file_name}
                              modalOpen={this.state.modalOpen}
                              handleClose={this.modalClose}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {ipo && ipo.presentation && (
                    <div className="col-md-12 mb-4">
                      <div className="smd-file-detail clearfix">
                        <div className="file-image">
                          <img src={FileUpload} alt="" width={42} />
                        </div>
                        <div className="file-meta">
                          <span className="name">Company Presentation</span>
                          <div className="view clearfix">
                            <a
                              target="_blank"
                              href={`${BaseUrl}storage/${ipo.presentation.id}/${ipo.presentation.file_name}`}
                            >
                              View Document
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {ipo && ipo.prospectus && (
                    <div className="col-md-12 mb-4">
                      <div className="smd-file-detail clearfix">
                        <div className="file-image">
                          <img src={FileUpload} alt="" width={42} />
                        </div>
                        <div className="file-meta">
                          <span className="name">Prospectus</span>
                          <div className="view clearfix">
                            <a
                              target="_blank"
                              href={`${BaseUrl}storage/${ipo.prospectus.id}/${ipo.prospectus.file_name}`}
                            >
                              View Document
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {ipo && ipo.additional_document && (
                    <div className="col-md-12 mb-4">
                      <div className="smd-file-detail clearfix">
                        <div className="file-image">
                          <img src={FileUpload} alt="" width={42} />
                        </div>
                        <div className="file-meta">
                          <span className="name">
                            {ipo.additional_document_name}
                          </span>
                          <div className="view clearfix">
                            <a
                              target="_blank"
                              href={`${BaseUrl}storage/${ipo.additional_document.id}/${ipo.additional_document.file_name}`}
                            >
                              View Document
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {ipo &&
                    ipo.documents &&
                    ipo.documents.length > 0 &&
                    ipo.documents.map((additional_document) => (
                      <div className="col-md-12 mb-4">
                        <div className="smd-file-detail clearfix">
                          <div className="file-image">
                            <img src={FileUpload} alt="" width={42} />
                          </div>
                          <div className="file-meta">
                            <span className="name">
                              {additional_document.name}
                            </span>
                            {additional_document.document && (
                              <div className="view clearfix">
                                <a
                                  target="_blank"
                                  href={`${BaseUrl}storage/${additional_document.document.id}/${additional_document.document.file_name}`}
                                >
                                  View Document
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <p style={{ marginTop: 10 }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: ipo && ipo.details,
                    }}
                  />
                  <br />
                  {ipo && ipo.type === "180" && ipo.helpful_links && (
                    <>
                      <b>Helpful Links: </b>{" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: ipo.helpful_links,
                        }}
                      />
                    </>
                  )}
                </p>
                <p style={{ marginTop: 30 }}>
                  {ipo && ipo.summary && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: ipo.summary,
                      }}
                    />
                  )}
                  <br />
                  {ipo && ipo.type === "180" && (
                    <i>
                      180 Markets PTY LTD reserves the right to close this deal
                      early.
                      <br />
                      180 Markets PTY LTD or its associated entities may invest
                      in this deal.
                    </i>
                  )}
                </p>
              </div>
              <div className="col-md-5">
                {/* {currentUser &&
                  currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.is_approved === "Approved" &&
                  ipo &&
                  !ipo.myindication && (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        <IpoForm
                          currentUser={currentUser}
                          onSubmit={this.onSubmitBid}
                          selectEntityOptions={this.state.selectEntityOptions}
                          currentDeal={ipo}
                          bidStatus={bidStatus}
                        />
                      </div>
                    </div>
                  )} */}

                {currentUser &&
                  currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.is_approved === "Approved" &&
                  !currentUser.si_form_expired &&
                  ipo &&
                  ipo.status !== "Closed" &&
                  ipo.status !== "Finished" &&
                  ipo.status !== "Suspended" &&
                  ipo.type === "180" &&
                  (ipo.myBids.length === 0 || anotherBid) &&
                  !ipo.myindication && (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        <IpoForm
                          currentUser={currentUser}
                          onSubmit={this.onSubmitBid}
                          selectEntityOptions={entitiesNotUsed}
                          currentDeal={ipo}
                          ipo={ipo}
                          bidStatus={bidStatus}
                        />
                      </div>
                    </div>
                  )}

                {currentUser &&
                  currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.is_approved === "Approved" &&
                  !currentUser.si_form_expired &&
                  ipo.status !== "Closed" &&
                  ipo.status !== "Finished" &&
                  ipo.status !== "Suspended" &&
                  ipo &&
                  ipo.type === "market" &&
                  !ipo.myindication && (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        <IpoForm
                          currentUser={currentUser}
                          onSubmit={this.onSubmitBid}
                          selectEntityOptions={this.state.selectEntityOptions}
                          currentDeal={ipo}
                          ipo={ipo}
                          bidStatus={bidStatus}
                        />
                      </div>
                    </div>
                  )}
                {currentUser &&
                  currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.is_approved === "Approved" &&
                  !currentUser.si_form_expired &&
                  ipo &&
                  ipo.status !== "Closed" &&
                  ipo.status !== "Finished" &&
                  ipo.status !== "Suspended" &&
                  ipo.type === "180" &&
                  ipo.myBids.length === 0 &&
                  ipo.myindication && (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        <IpoForm
                          currentUser={currentUser}
                          onSubmit={this.onSubmitBid}
                          selectEntityOptions={this.state.selectEntityOptions}
                          currentDeal={ipo}
                          ipo={ipo}
                          bidStatus={bidStatus}
                        />
                      </div>
                    </div>
                  )}

                {currentUser &&
                  currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.is_approved === "Approved" &&
                  !currentUser.si_form_expired &&
                  ipo &&
                  ipo.status !== "Closed" &&
                  ipo.status !== "Finished" &&
                  ipo.status !== "Suspended" &&
                  ipo.type === "market" &&
                  !ipo.indication &&
                  ipo.mybid && (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        <IpoForm
                          currentUser={currentUser}
                          onSubmit={this.onSubmitBid}
                          selectEntityOptions={this.state.selectEntityOptions}
                          currentDeal={ipo}
                          ipo={ipo}
                          bidStatus={bidStatus}
                        />
                      </div>
                    </div>
                  )}

                {currentUser &&
                  currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.is_approved === "Approved" &&
                  !currentUser.si_form_expired &&
                  ipo &&
                  // !ipo.status &&
                  ipo.type === "180" &&
                  ipo.myBids &&
                  !anotherBid &&
                  ipo.myBids.length > 0 && (
                    <div>
                      {ipo.myBids.map((bid) => (
                        <div className="md-deal-form mb-4">
                          <div>
                            <b>Entity: </b>
                            {bid.entity?.entity_name}
                          </div>
                          <div>
                            <b>Share Count: </b>
                            {bid.share_count}
                          </div>
                          <div className="mb-2">
                            <b>Investment Amount: </b>
                            <NumberFormat
                              value={bid.investment_amount}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </div>
                          {bid.allocation_investor_count === 0 && (
                            <div className="form-normal">
                              <button
                                type="button"
                                // className="btn btn-outline-primary btn-medium"
                                className="btn btn-outline-primary btn-medium btn-block"
                                onClick={() =>
                                  this.editBidHandler(ipo.uuid, bid.uuid)
                                }
                              >
                                Change Bid
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                      {entitiesNotUsed.length > 0 && (
                        <>
                          <button
                            type="button"
                            className="btn btn-outline-default btn-medium btn-block mt-4"
                            onClick={() => this.onBidNow(ipo.uuid)}
                          >
                            Bid with another entity
                          </button>
                        </>
                      )}
                    </div>
                  )}

                {currentUser &&
                  currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.is_approved === "Approved" &&
                  !currentUser.si_form_expired &&
                  ipo &&
                  !ipo.status &&
                  ipo.type === "market" &&
                  ipo.myindication && (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-medium btn-block"
                          onClick={() =>
                            this.editIndicationHandler(
                              ipo.uuid,
                              ipo.myindication.uuid
                            )
                          }
                        >
                          Change Indication
                        </button>
                      </div>
                    </div>
                  )}

                {currentUser &&
                  currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.is_approved === "Pending" && (
                    <div className="md-deal-form">
                      <div className="form-normal dealBidMessage">
                        <div className="deal-form-content">
                          <p className="bidMessage">
                            Complete your profile to bid for deals
                          </p>
                          {/*<button*/}
                          {/*  className="btn btn-outline-primary btn-medium"*/}
                          {/*  onClick={() => this.props.history.push("/user/profile")}*/}
                          {/*>*/}
                          {/*  Profile*/}
                          {/*</button>*/}
                        </div>
                      </div>
                    </div>
                  )}
                {currentUser &&
                  currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.is_approved === "Approved" &&
                  currentUser.si_form_expired && (
                    <div className="md-deal-form">
                      <div className="form-normal dealBidMessage">
                        <div className="deal-form-content">
                          <p className="bidMessage">
                            Your current sophisticated investor form has
                            expired. Please upload an updated sophisticated
                            investor form to bid.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                {currentUser &&
                  currentUser.role &&
                  currentUser.role.some(
                    (role) => role.title !== "Investor"
                  ) && (
                    <div className="md-deal-form">
                      <div className="form-normal dealBidMessage">
                        <div className="deal-form-content">
                          <p className="bidMessage">
                            Register as an investor to bid for deal
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                {ipo ? (
                  ipo.status === "Closed" || ipo.status === "Finished" ? (
                    <div className="md-deal-form">
                      <div className="form-normal dealBidMessage">
                        <div
                          className="deal-form-content"
                          style={{ textAlign: "center" }}
                        >
                          <p
                            style={{
                              fontSize: "18px",
                              fontWeight: "regular",
                              paddingBottom: 50,
                              paddingTop: 50,
                            }}
                          >
                            This deal has closed.
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null
                ) : null}
                {ipo ? (
                  ipo.status === "Suspended" ? (
                    <div className="md-deal-form">
                      <div className="form-normal dealBidMessage">
                        <div
                          className="deal-form-content"
                          style={{ textAlign: "center" }}
                        >
                          <p
                            style={{ fontSize: "18px", fontWeight: "bold" }}
                            className="bidMessage"
                          >
                            This deal is already suspended.
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : null
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ipo: state.ipos.selectedIpo,
    currentUser: state.auth.currentUser,
    existingEntity: state.entities.entities,
  };
};

const { fetchSelectedIpo, submitIndication, submitBid } = IpoActions;
const { fetchEntities } = EntityActions;

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllEntities: (payload, history) =>
      fetchEntities({ dispatch, payload, history }),
    fetchSelectedIpoData: (uuid, history) => fetchSelectedIpo(uuid, dispatch),
    submitIpoIndication: (payload, history) =>
      submitIndication(payload, history, dispatch),
    submitBid: (payload, history) => submitBid(payload, history, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(IpoView));
