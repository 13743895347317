import React, { Component } from "react";
import { Modal } from "antd";
import moment from "moment";
import NumberFormat from "react-number-format";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FileUpload } from "../../../common/Graphics";
import DealsViewForm from "./DealsViewForm/DealsViewForm";
import { connect } from "react-redux";
import EntityActions from "../../../../redux/actions/entityActions";
import dealActions from "../../../../redux/actions/dealActions";
import { withRouter } from "react-router-dom";
import BidActions from "../../../../redux/actions/bids.Actions";
import { BaseUrl } from "../../../../services/settings";
import ModalComponent from "../../../common/Modal";
import { renderData } from "../../../common/CommonMethods";

const queryString = require("query-string");

class DealView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectEntityOptions: [],
      open: false,
      openLightBox: false,
      modalOpen: false,
      file: "",
      anotherBid: false,
      selectedBid: null,
    };
  }

  openLightBox = (file) => {
    this.setState({ openLightBox: true, file });
  };

  closeLightBox = () => {
    this.setState({ openLightBox: false });
  };

  modalOpen = () => {
    this.setState({ modalOpen: true });
  };

  modalClose = () => {
    this.setState({ modalOpen: false });
  };

  componentDidMount = () => {
    let {
      getSingleDealData,
      fetchAllEntities,
      match,
      currentUser,
      getDealStatus,
      history,
    } = this.props;
    if (currentUser && localStorage.getItem("auth_token")) {
      fetchAllEntities();
      getDealStatus(match.params.uuid);
    }
    const isApproved = currentUser.is_approved === "Approved" ? true : false;
    if (!isApproved) {
      history.push("/user/deals");
    }
    getSingleDealData(match.params.uuid);
  };

  renderIndividualName = (entity) => {
    let name = "";
    for (let key in entity.entity_individuals) {
      if (name.length > 0) {
        return (name +=
          " " +
          "&" +
          " " +
          entity.entity_individuals[key].given_name +
          " " +
          entity.entity_individuals[key].sur_name +
          " " +
          `${
            entity.entity_individuals[key].nickname
              ? `(${entity.entity_individuals[key].nickname})`
              : ""
          }`);
      }

      name +=
        entity.entity_individuals[key].given_name +
        " " +
        entity.entity_individuals[key].sur_name +
        " " +
        `${
          entity.entity_individuals[key].nickname
            ? `(${entity.entity_individuals[key].nickname})`
            : ""
        }`;
    }

    return name;
  };

  componentWillReceiveProps = (nextProps) => {
    if (
      this.props.existingEntity !== nextProps.existingEntity &&
      nextProps.currentUser.id
    ) {
      this.setState({
        selectEntityOptions:
          nextProps.existingEntity &&
          nextProps.existingEntity.map((entity) => {
            if (entity.entity_company !== null)
              return {
                value: entity.id,
                text: `${entity.broker_name} - ${entity.entity_company.name} ${
                  entity.entity_company.nickname
                    ? `(${entity.entity_company.nickname})`
                    : ""
                }`,
                is_si_form_expired: entity.is_si_form_expired,
              };
            else if (entity.entity_trust !== null)
              return {
                value: entity.id,
                text: `${entity.broker_name} - ${entity.entity_trust.name} ${
                  entity.entity_trust.nickname
                    ? `(${entity.entity_trust.nickname})`
                    : ""
                }`,
                value: entity.id,
                is_si_form_expired: entity.is_si_form_expired,
              };
            else if (entity.entity_individual !== null)
              return {
                value: entity.id,
                text: `${entity.broker_name} - ${this.renderIndividualName(
                  entity
                )}`,
                value: entity.id,
                is_si_form_expired: entity.is_si_form_expired,
              };
          }),
      });
    }
  };

  cancelBidHanler = (id) => {
    let { cancelBid, history } = this.props;

    this.setState({ open: false });
    cancelBid({ bidId: id }, history);
  };

  editBidHandler = (id, bidId) => {
    const { location } = this.props;

    const parsed = queryString.parse(location.search);
    if (parsed) {
      if (parseInt(parsed.bid) === 1) {
        this.props.history.push(`/user/edit-bid/${id}/${bidId}?bid=1`);
      } else {
        this.props.history.push(`/user/edit-bid/${id}/${bidId}`);
      }
    } else {
      this.props.history.push(`/user/edit-bid/${id}/${bidId}`);
    }
  };

  onSubmitBid = async (values) => {
    let {
      createBidSubmit,
      currentDeal,
      bidStatus,
      currentUser,
      createIndicationSubmit,
      history,
      match,
    } = this.props;
    if (currentDeal.type === "market") {
      createIndicationSubmit(
        {
          ...values,
          deal_id: currentDeal.id,
          investor_id: currentUser.id,
        },
        history
      );
    } else if (currentDeal.type === "180") {
      Modal.confirm({
        title: "Confirm",
        width: 700,
        okButtonProps: {
          type: "primary",
          size: "large",
        },
        cancelButtonProps: {
          size: "large",
        },
        icon: <ExclamationCircleOutlined />,
        content: (
          <p className="pconfirm">
            By submitting a bid, you are making a binding and irrevocable offer
            to acquire the securities, on the terms set out in the information
            available here, in the{" "}
            {currentDeal && currentDeal.term_sheets ? (
              <a
                target="_blank"
                href={`${BaseUrl}storage/${currentDeal.term_sheets.id}/${currentDeal.term_sheets.file_name}`}
              >
                Term sheet
              </a>
            ) : (
              "Term sheet"
            )}{" "}
            and in the{" "}
            {
              <a
                target="_blank"
                href="https://180markets.com.au/investor/terms&conditions"
              >
                Investor Terms
              </a>
            }
            . You confirm you make each of the representations and warranties
            specified in the Investor Terms. Your bid does not guarantee that
            you will receive an allocation. If your bid is successful, you will
            be required to pay to acquire the securities in accordance with your
            bid and the Investor Terms, subject to any scale back.
          </p>
        ),
        okText: "Confirm",
        cancelText: "Cancel",
        async onOk() {
          await createBidSubmit(
            {
              ...values,
              deal_id: currentDeal.id,
              deal_uuid: match.params.uuid,
            },
            history
          );
        },
        onCancel() {},
      });
    }
  };

  onBidNow = (id) => {
    this.setState({
      anotherBid: true,
      selectedBid: null,
    });
    // this.props.onBidNow(id, true);
  };

  render() {
    let { currentDeal, currentUser, bidStatus, location } = this.props;
    const parsed = queryString.parse(location.search);

    const { selectedBid, anotherBid, selectEntityOptions } = this.state;
    let entitiesNotUsed = [...selectEntityOptions].filter((entity) => {
      return !(
        bidStatus &&
        bidStatus.myBids &&
        bidStatus.myBids.some((bid) => bid.entity_id === entity.value)
      );
    });

    return (
      <main className="page-deal-add-doc">
        <div className="md-top-deal">
          <div className="container">
            <div className="deal-inner">
              <div className="row">
                <div className="col-md-2">
                  <div className="logo">
                    <div className="inner">
                      <img
                        src={
                          currentDeal &&
                          currentDeal.deal_logo &&
                          `${BaseUrl}/storage/${currentDeal.deal_logo.id}/${currentDeal.deal_logo.file_name}`
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="information">
                    <h1>{currentDeal && currentDeal.company_name}</h1>
                    {currentDeal && currentDeal.asx_code && (
                      <p className="sub-title">
                        ASX:
                        {currentDeal.asx_code}
                      </p>
                    )}
                    <div className="detail">
                      <div className="row">
                        {currentDeal && currentDeal.last_price && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Last Price</p>
                              <p className="number">
                                ${currentDeal.last_price}
                              </p>
                            </div>
                          </div>
                        )}
                        {currentDeal && currentDeal.capital_raise && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Capital Raise Price</p>
                              <p className="number">
                                {`$${currentDeal.capital_raise}`}
                              </p>
                            </div>
                          </div>
                        )}
                        {currentDeal && currentDeal.raise_amount && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Raise Amount</p>
                              <p className="number">
                                {`${renderData(currentDeal.raise_amount)}`}
                              </p>
                            </div>
                          </div>
                        )}
                        {currentDeal && currentDeal.discount && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Discount </p>
                              <p className="number">
                                {currentDeal.discount + " %"}
                              </p>
                            </div>
                          </div>
                        )}
                        {currentDeal && currentDeal.market_capital && (
                          <div className="col-sm-2">
                            <div className="inner">
                              <p className="text">Market Capitalisation</p>
                              <p className="price">
                                {renderData(currentDeal.market_capital)}
                              </p>
                            </div>
                          </div>
                        )}
                        {currentDeal &&
                          currentDeal.free_option &&
                          currentDeal.dividend_option && (
                            <div className="col-sm-2">
                              <div className="inner">
                                <p className="text">Free Options</p>
                                <p className="price">
                                  {`${currentDeal.dividend_option}:${currentDeal.free_option} ${currentDeal?.option_details ? currentDeal.option_details : '' }`}
                                </p>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md-tabs tab-deal-add-doc">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                {currentDeal && currentDeal.bids_due && (
                  <p>
                    <b>Bids Due: </b>{" "}
                    {moment(currentDeal.bids_due, "DD/MM/YYYY HH:mm").format(
                      "MMM. DD, YYYY hh:mm A"
                    )}
                  </p>
                )}
                {currentDeal && currentDeal.settlement_date && (
                  <p>
                    <b>Settlement Date: </b>{" "}
                    {moment(currentDeal.settlement_date, "DD/MM/YYYY").format(
                      "MMM. DD, YYYY"
                    )}
                  </p>
                )}

                <div className="row mt-5">
                  {currentDeal && (
                    <div className="col-md-12 mb-4">
                      {currentDeal && currentDeal.term_sheets && (
                        <div className="smd-file-detail clearfix">
                          <div className="file-image">
                            <img src={FileUpload} alt="" width={42} />
                          </div>
                          <div className="file-meta">
                            <span className="name">Term Sheet</span>
                            <div className="view clearfix">
                              <a
                                target="_blank"
                                href={`${BaseUrl}storage/${currentDeal.term_sheets.id}/${currentDeal.term_sheets.file_name}`}
                              >
                                View document
                              </a>
                            </div>
                            <ModalComponent
                              fileName={currentDeal.term_sheets.file_name}
                              modalOpen={this.state.modalOpen}
                              handleClose={this.modalClose}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {currentDeal && currentDeal.information_overview && (
                    <div className="col-md-12 mb-4">
                      <div className="smd-file-detail clearfix">
                        <div className="file-image">
                          <img src={FileUpload} alt="" width={42} />
                        </div>
                        <div className="file-meta">
                          <span className="name">Company Presentation</span>
                          <div className="view clearfix">
                            <a
                              target="_blank"
                              href={`${BaseUrl}storage/${currentDeal.information_overview.id}/${currentDeal.information_overview.file_name}`}
                            >
                              View Document
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {currentDeal &&
                    currentDeal.documents &&
                    currentDeal.documents.length > 0 &&
                    currentDeal.documents.map((additional_document) => (
                      <div className="col-md-12 mb-4">
                        <div className="smd-file-detail clearfix">
                          <div className="file-image">
                            <img src={FileUpload} alt="" width={42} />
                          </div>
                          <div className="file-meta">
                            <span className="name">
                              {additional_document.name}
                            </span>
                            {additional_document.document && (
                              <div className="view clearfix">
                                <a
                                  target="_blank"
                                  href={`${BaseUrl}storage/${additional_document.document.id}/${additional_document.document.file_name}`}
                                >
                                  View Document
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <p style={{ marginTop: 30 }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentDeal && currentDeal.details,
                    }}
                  />

                  <br />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentDeal && currentDeal.helpful_links,
                    }}
                  />

                  <br />
                  {currentDeal && currentDeal.type === "180" && (
                    <i>
                      180 Markets PTY LTD reserves the right to close this deal
                      early.
                      <br />
                      180 Markets PTY LTD or its associated entities may invest
                      in this deal.
                    </i>
                  )}
                </p>
                <p>{}</p>
              </div>
              <div className="col-md-5">
                {currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.is_approved === "Approved" &&
                  !currentUser.si_form_expired &&
                  bidStatus &&
                  currentDeal &&
                  bidStatus.myBids &&
                  (bidStatus.myBids.length === 0 || anotherBid) &&
                  !bidStatus.myIndication &&
                  !parsed.bid &&
                  (currentDeal.status === "Active" ? (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        {currentDeal.type === "180" && (
                          <h3
                            className="text-capitalize"
                            style={{ paddingLeft: 20, paddingTop: 20 }}
                          >
                            Submit Bid for {currentDeal.company_name}
                          </h3>
                        )}

                        <DealsViewForm
                          currentUser={currentUser}
                          onSubmit={this.onSubmitBid}
                          selectEntityOptions={entitiesNotUsed}
                          capRaisePrice={
                            currentDeal && currentDeal.capital_raise
                          }
                          currentDeal={currentDeal}
                          bidStatus={bidStatus}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="md-deal-form">
                      {/* <h3>This Placement has closed</h3> */}
                    </div>
                  ))}

                {currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.is_approved === "Approved" &&
                  !currentUser.si_form_expired &&
                  bidStatus &&
                  currentDeal &&
                  bidStatus.myBids &&
                  bidStatus.myBids.length > 0 &&
                  currentDeal.status !== "Closed" &&
                  !anotherBid &&
                  (currentDeal.status === "Active" ||
                  parseInt(parsed.bid) === 1 ? (
                    <>
                      <h3>Change Bid</h3>
                      {bidStatus.myBids.map((bid) => (
                        <div className="md-deal-form mb-4">
                          <div>
                            <b>Entity: </b>
                            {bid.entity?.entity_name}
                          </div>
                          <div>
                            <b>Share Count:</b> {bid.share_count}
                          </div>
                          <div className="mb-2">
                            <b>Investment Amount:</b>
                            <NumberFormat
                              value={bid.investment_amount}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </div>
                          {bid.allocation_investor_count === 0 && (
                            <div className="form-normal">
                              <button
                                type="button"
                                // className="btn btn-outline-primary btn-medium"
                                className="btn btn-outline-primary btn-medium btn-block"
                                onClick={() =>
                                  this.editBidHandler(bidStatus.uuid, bid.uuid)
                                }
                              >
                                Change Bid
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                      {entitiesNotUsed.length > 0 && (
                        <>
                          <button
                            type="button"
                            className="btn btn-outline-default btn-medium btn-block mt-4"
                            onClick={() => this.onBidNow(bidStatus.uuid)}
                          >
                            Bid with another entity
                          </button>
                        </>
                      )}
                    </>
                  ) : (
                    <div className="md-deal-form">
                      {/* <h3>This Placement has closed</h3> */}
                    </div>
                  ))}

                {currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  currentUser.is_approved === "Approved" &&
                  !currentUser.si_form_expired &&
                  bidStatus &&
                  currentDeal &&
                  !anotherBid &&
                  currentDeal.status !== "Closed" &&
                  bidStatus.myIndication && (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-medium btn-block"
                          onClick={() => this.editBidHandler(bidStatus.uuid)}
                        >
                          Change Indication
                        </button>
                      </div>
                    </div>
                  )}

                {currentUser.role &&
                  currentUser.role.some((role) => role.title === "Investor") &&
                  (currentUser.is_approved === "Pending" ||
                    currentUser.is_approved === "Rejected") && (
                    <div className="md-deal-form">
                      <div className="form-normal dealBidMessage">
                        <div className="deal-form-content">
                          <p className="bidMessage">
                            Complete your profile to bid for deals
                          </p>
                          <button
                            className="btn btn-outline-primary btn-block"
                            onClick={() =>
                              this.props.history.push("/user/profile")
                            }
                          >
                            Go to Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                {currentUser.role &&
                currentUser.role.some((role) => role.title === "Investor") &&
                currentUser.is_approved === "Approved" &&
                currentUser.si_form_expired ? (
                  <div className="md-deal-form">
                    <div className="form-normal dealBidMessage">
                      <div className="deal-form-content">
                        <p className="bidMessage">
                          Your current sophisticated investor form has expired.
                          Please upload an updated sophisticated investor form
                          to bid.
                        </p>
                        <button
                          className="btn btn-outline-primary btn-block"
                          onClick={() =>
                            this.props.history.push("/user/profile")
                          }
                        >
                          Go to Profile
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {currentUser.role &&
                  currentUser.role.some(
                    (role) => role.title !== "Investor"
                  ) && (
                    <div className="md-deal-form">
                      <div className="form-normal dealBidMessage">
                        <div className="deal-form-content">
                          <p className="bidMessage">
                            Register as an investor to bid for deal
                          </p>
                        </div>
                      </div>
                    </div>
                  )}

                {(currentDeal && !currentUser) ||
                  (currentDeal && !localStorage.getItem("auth_token") && (
                    <div className="md-deal-form">
                      <div className="form-normal dealBidMessage">
                        <div
                          className="deal-form-content"
                          style={{ textAlign: "center" }}
                        >
                          <p className="bidMessage">Login to bid for deals</p>
                          <div className="row" style={{ padding: "20px 20px" }}>
                            <div className="col-sm-12">
                              <button
                                className="btn btn-primary btn-medium btn-block"
                                onClick={() =>
                                  this.props.history.push("/auth/login")
                                }
                              >
                                Login
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {(currentDeal && currentDeal.status === "Closed") ||
                (currentDeal && currentDeal.status === "Finished") ? (
                  <div className="md-deal-form">
                    <div
                      className="form-normal dealBidMessage"
                      style={{ minHeight: 250 }}
                    >
                      <div
                        className="deal-form-content"
                        style={{ textAlign: "center" }}
                      >
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "regular",
                            paddingBottom: 10,
                            paddingTop: 50,
                          }}
                        >
                          This Placement has closed.
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}

                {(currentDeal &&
                  currentDeal.status === "Suspended" &&
                  !parsed) ||
                (currentDeal &&
                  currentDeal.status === "Suspended" &&
                  parsed.bid &&
                  parseInt(parsed.bid) !== 1) ? (
                  <div className="md-deal-form">
                    <div
                      className="form-normal dealBidMessage"
                      style={{ minHeight: 250 }}
                    >
                      <div
                        className="deal-form-content"
                        style={{ textAlign: "center" }}
                      >
                        <p
                          style={{
                            fontSize: "18px",
                            fontWeight: "regular",
                            paddingBottom: 10,
                            paddingTop: 50,
                          }}
                        >
                          This deal is already suspended.
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  parseInt(parsed.bid) === 1 &&
                  (bidStatus.myBids.length === 0 || anotherBid) && (
                    <div className="md-deal-form">
                      <div className="form-normal">
                        <DealsViewForm
                          queryString={parsed.bid}
                          currentUser={currentUser}
                          onSubmit={this.onSubmitBid}
                          selectEntityOptions={this.state.selectEntityOptions}
                          capRaisePrice={
                            currentDeal && currentDeal.capital_raise
                          }
                          currentDeal={currentDeal}
                          bidStatus={bidStatus}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    existingEntity: state.entities.entities,
    currentDeal: state.deals.singleDeal,
    currentUser: state.auth.currentUser,
    bidStatus: state.deals.bidStatus,
  };
};

const { fetchEntities } = EntityActions;
let { singlePublicDeal, getSingleDeal } = dealActions;
let { createBid, deleteBid, createIndication } = BidActions;

const mapDispatchToProps = (dispatch) => ({
  fetchAllEntities: (payload, history) =>
    fetchEntities({ dispatch, payload, history }),
  getSingleDealData: (payload) => singlePublicDeal(payload, dispatch),
  getDealStatus: (payload) => getSingleDeal(payload, dispatch),
  createBidSubmit: (payload, history) => createBid(payload, dispatch, history),
  createIndicationSubmit: (payload, history) =>
    createIndication(payload, dispatch, history),
  cancelBid: (payload, history) => deleteBid(payload, dispatch, history),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DealView));
